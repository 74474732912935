

*,
*::before,
*::after {  -webkit-box-sizing: border-box;  box-sizing: border-box; }

html {
  font-family: sans-serif;  line-height: 1.15;
  -webkit-text-size-adjust: 100%;  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body{background-color: #fff}



/**** LOGIN ******/


  .overlu{position: absolute;left: 0;top: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, .5);}
  .login-wrap {  position: relative;  color: rgba(255, 255, 255, 0.9); }
  .login-wrap h3 { font-weight: 300;  color: #fff;width: 100% }
  .login-wrap .social {    width: 100%; }
  .login-wrap .social a {color: #000;background: #fff; 
        width: 100%;display: block;border: 1px solid rgba(255, 255, 255, 0.4);   }
  .login-wrap .social a:hover {background: #000;color: #fff; border-color: #000; }
  .login-wrap img{  margin: 0px auto;display: block;  }

  .form-group input {border-color: rgba(255, 255, 255, 0.4);
    background-color: transparent;border: none;height: 50px; color: #fff !important;
    border: 1px solid #fff;  background: rgba(255, 255, 255, 0.08);
    border-radius: 40px;  padding-left: 20px;  padding-right: 20px;
    -webkit-transition: 0.3s;  -o-transition: 0.3s;  transition: 0.3s; }
  .form-group input[type=submit] {font-size: 1.2rem;
    border-radius: 40px;height: 50px;  font-weight: bold;color: #ce9f4e !important;text-shadow: 2px 2px 2px #460B09;
   border-color: rgba(255, 255, 255, 0.4);background-color: #04c4cb}
  .form-group input[type=submit]:hover{
    background-color: #ce9f4e;color: #04c4cb !important  } 
  .form-group input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */color: rgba(255, 255, 255, 0.8) !important; }
  .form-group input::-moz-placeholder {
      /* Firefox 19+ */color: rgba(255, 255, 255, 0.8) !important; }
  .form-group input:-ms-input-placeholder {
      /* IE 10+ */color: rgba(255, 255, 255, 0.8) !important; }
  .form-group input:-moz-placeholder {
      /* Firefox 18- */color: rgba(255, 255, 255, 0.8) !important; }
  .form-group input:hover, .form-group input:focus {
      background: transparent;outline: none;-webkit-box-shadow: none;
      box-shadow: none;border-color: rgba(255, 255, 255, 0.4); }
  .form-control:focus {border-color: rgba(255, 255, 255, 0.4); }
  .field-icon {  position: absolute;  cursor: pointer;padding-bottom: 45px;
    right: 15px;-webkit-transform: translateY(-50%);transform: translateY(-50%);
    -ms-transform: translateY(-50%);   color: rgba(255, 255, 255, 0.9); }
  .field-icon:hover{   color: #ffd700;}
  .cont_login:after {position: absolute;z-index:-1;top: 0; left: 0; right: 0;bottom: 0;
               content: '';background: #000;opacity: .3;}
  .cont_login{position: relative;z-index: 2;background-attachment: fixed;
    background-size: cover ;background-repeat: no-repeat;
    background-position: 100%;width: 100%;height: 100vh}
  .cont_login_body{margin-top: 100px}
  .checkbox-wrap {user-select: none;
    display: block;position: relative;padding-left: 30px;margin-bottom: 12px;cursor: pointer;font-size: 16px;
    font-weight: 500;-webkit-user-select: none; -moz-user-select: none;-ms-user-select: none;   }
  /* Hide the browser's default checkbox */
  .checkbox-wrap input {position: absolute;opacity: 0;cursor: pointer;height: 0; width: 0; }

  /* Create a custom checkbox */
  .checkmark {  position: absolute;top: 0; left: 0; }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after { content: "\f0c8";font-family: 'Font Awesome\ 5 Free';

    position: absolute;color: rgba(255, 255, 255, 0.1);  font-size: 20px; margin-top: -4px;
    -webkit-transition: 0.3s; -o-transition: 0.3s;  transition: 0.3s; }
  @media (prefers-reduced-motion: reduce) {
    .checkmark:after { -webkit-transition: none;-o-transition: none; transition: none; } 
    }
  /* Show the checkmark when checked */
  .checkbox-wrap input:checked ~ .checkmark:after {
    display: block;  content: "\f14a"; font-family: 'Font Awesome\ 5 Free';  color: rgba(0, 0, 0, 0.2); }
  /* Style the checkmark/indicator */
  .checkbox-primary {  color: #fbceb5; }
  .checkbox-primary input:checked ~ .checkmark:after {    color: #fbceb5; }
  .login-wrap h3{ font-family: 'Kaushan Script', cursive;}
  .volver  a{ color:#fff;font-size: 1.5rem;
    position: absolute;top:10px;font-family: 'Kaushan Script', cursive !important;  }
 



 
/** VALIDACION **/
  .eror{background-color: #000!important;position: absolute;padding: 5px;
      font-family: 'Kaushan Script', cursive !important;  left: 10%}
  input.success{  border:1px solid #008000 ; background-color: #BDF9B2;color: #000 !important}
  label.invalid{  color: #DA0A13;font-size: 1rem;position: absolute;background-color: #000}
  input.invalid{box-shadow: inset 0 1px 4px rgba(255, 0, 0, 0.6), 0 0 4px rgba(255, 0, 0, 0.6);
                background-color: #F3A7B8}
  input.success:focus {   color: #fff !important;}





  .ajs-header,.ajs-button{  font-family: 'Kaushan Script', cursive !important;text-shadow: 2px 2px 2px #460b09 !important;}
  .ajs-error{ background-color: #FE1A00!important;font-family: 'Kaushan Script', cursive !important;color: #fff !important}
  .ulerror{  color: #FE1A00;  } 
  .h2success{  color:#fff;text-align: center;}
  .ajs-success {background-color: #5CB811!important;font-family: 'Kaushan Script', cursive !important;
                text-shadow: 2px 2px 2px #460b09 !important;color:#000!important;}
  .tit-regis{  font-family: 'Kaushan Script', cursive !important;}
  .loginingres{ font-family: 'Kaushan Script', cursive !important;color: #fff;font-size: 1.5rem}
  .ajs-button{ color: #fff!important;background-color: #FE1A00!important}
  .ajs-header{ color: #fff!important;background-color: #FE1A00 !important;}

 
 

 
 
         
 
 

 
 




/*** MEDIA QUERY ******/
 
  @media only screen and(max-width:807px){

    .ulerror{    font-size: .7rem;margin-left: -55px; width: 160px; }
    .ul_success{font-size: .7rem;margin-left: -55px; width: 160px;   } 
  }
 
  @media only screen and (max-width: 575.98px){

    .cont_register_body{margin-top: 40px}
  }

  @media only screen and (max-width: 355.98px){

      .cont_login_body{      margin-top: 20px    }
      .cont_register_body{margin-top: 0px}
  }